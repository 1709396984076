var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "py-5 white--text secondary", attrs: { id: "demo" } },
    [
      _c("v-container", [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-center flex-wrap justify-center text-md-left text-center"
          },
          [
            _c("h3", { staticClass: "headline font-weight-light" }, [
              _vm._v(
                "Check out our documentation to see how to set up a course!"
              )
            ]),
            _c("v-spacer", { staticClass: "hidden-sm-and-down" }),
            _c(
              "v-btn",
              {
                staticClass: "my-3",
                attrs: {
                  href: "https://docs.nuvolos.cloud",
                  large: "",
                  "min-width": "128",
                  rel: "noopener",
                  rounded: "",
                  target: "_blank"
                }
              },
              [_vm._v("Go to the Documentation")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }